.carousel .slide .legend {
  bottom: 50px;
  width: 15%;
  min-width: 150px;
  right: 110px;
  left: auto;
}

@media screen and (max-width: 480px) {
  .carousel .slide .legend {
    display: none;
  }
}

.carousel-status {
  display: none;
}

.carousel.carousel-slider .control-arrow {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.15);
  height: 50px;
  margin: auto auto;
}

.carousel.carousel-slider .control-arrow.control-next {
  border-radius: 0px 8px 8px 0px;
}

.carousel.carousel-slider .control-arrow.control-prev {
  border-radius: 8px 0px 0px 8px;
}

@import './styles/master';
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,light,regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Karla:300,400,500,600,700,light,regular,bold,italic');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, -apple-system, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fdfdfd;
}

h1,
h2,
h3,
h4 {
  font-family: 'Poppins';
}

h1 {
  font-weight: 700;
  font-size: 1.75em;
  line-height: 2.25em;
}

h2 {
  font-weight: 500;
  font-size: 1.5em;
  line-height: 2em;
}

h3 {
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.75em;
}

p,
span {
  font-weight: 300;
  font-family: 'Karla';
  font-size: 1em;
  line-height: 1.5em;
}

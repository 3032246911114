#myWebGLCanvas {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 10;
}

#myImageCanvas {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
}

.footer {
  padding: 8px;
  height: 600px;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__content-text {
  text-align: center;
  margin-top: -15px;
  padding: 15px 10px;
  width: 300px;
}

.score {
  font-weight: bold;
  color: purple;
}

.shield {
  font-weight: bold;
  color: purple;
}

.earth {
  font-weight: bold;
  color: purple;
}

.alpha {
  font-weight: bold;
  color: purple;
}

.bravo {
  font-weight: bold;
  color: purple;
}

.charlie {
  font-weight: bold;
  color: purple;
}

.station1charge {
  font-weight: bold;
  color: purple;
}

.station2charge {
  font-weight: bold;
  color: purple;
}

.station3charge {
  font-weight: bold;
  color: purple;
}

.selected {
  font-weight: bold;
  color: purple;
  font-size: 14pt;
}

.restart {
  font-size: 8pt;
}

.loading {
  font-weight: bold;
  color: silver;
  font-size: 30pt;
  margin: 16px 0;
  display: none;
}

.page__content-container {
  margin: 100px auto;
  width: 800px;
  padding: 30px;
}

.earth-defense-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 800px;
  flex-wrap: wrap;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
}

.canvas-containers {
  height: 100%;
  position: relative;
  height: 600px;
  width: 800px;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 600px;
  width: 800px;
}

.canvas.top {
  z-index: 10;
}

.canvas.bottom {
  z-index: 5;
}

.footer {
  height: fit-content;
}

.stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4em;
}

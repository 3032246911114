@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,light,regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Karla:300,400,500,600,700,light,regular,bold,italic);
*{box-sizing:border-box}body{margin:0;padding:0;font-family:Roboto, -apple-system, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#fdfdfd}h1,h2,h3,h4{font-family:'Poppins'}h1{font-weight:700;font-size:1.75em;line-height:2.25em}h2{font-weight:500;font-size:1.5em;line-height:2em}h3{font-weight:400;font-size:1.25em;line-height:1.75em}p,span{font-weight:300;font-family:'Karla';font-size:1em;line-height:1.5em}

.carousel .slide .legend {
  bottom: 50px;
  width: 15%;
  min-width: 150px;
  right: 110px;
  left: auto;
}

@media screen and (max-width: 480px) {
  .carousel .slide .legend {
    display: none;
  }
}

.carousel-status {
  display: none;
}

.carousel.carousel-slider .control-arrow {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.15);
  height: 50px;
  margin: auto auto;
}

.carousel.carousel-slider .control-arrow.control-next {
  border-radius: 0px 8px 8px 0px;
}

.carousel.carousel-slider .control-arrow.control-prev {
  border-radius: 8px 0px 0px 8px;
}

#myWebGLCanvas {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 10;
}

#myImageCanvas {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
}

.footer {
  padding: 8px;
  height: 600px;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__content-text {
  text-align: center;
  margin-top: -15px;
  padding: 15px 10px;
  width: 300px;
}

.score {
  font-weight: bold;
  color: purple;
}

.shield {
  font-weight: bold;
  color: purple;
}

.earth {
  font-weight: bold;
  color: purple;
}

.alpha {
  font-weight: bold;
  color: purple;
}

.bravo {
  font-weight: bold;
  color: purple;
}

.charlie {
  font-weight: bold;
  color: purple;
}

.station1charge {
  font-weight: bold;
  color: purple;
}

.station2charge {
  font-weight: bold;
  color: purple;
}

.station3charge {
  font-weight: bold;
  color: purple;
}

.selected {
  font-weight: bold;
  color: purple;
  font-size: 14pt;
}

.restart {
  font-size: 8pt;
}

.loading {
  font-weight: bold;
  color: silver;
  font-size: 30pt;
  margin: 16px 0;
  display: none;
}

.page__content-container {
  margin: 100px auto;
  width: 800px;
  padding: 30px;
}

.earth-defense-container {
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 800px;
  flex-wrap: wrap;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
}

.canvas-containers {
  height: 100%;
  position: relative;
  height: 600px;
  width: 800px;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 600px;
  width: 800px;
}

.canvas.top {
  z-index: 10;
}

.canvas.bottom {
  z-index: 5;
}

.footer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 4em;
  gap: 4em;
}

